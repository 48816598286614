import React,{useEffect, useState} from 'react'
import {Helmet} from "react-helmet";

const CustomMetaHandle = (props)=>{
    const{seoData} = props
    const[seoKeyword, setSeoKeyword] = useState('');
    const[seoTitle, setSeoTitle] = useState('');
    const[seoDescription, setSeoDescription] = useState('');

    useEffect(() => {
        setSeoKeyword(seoData.seo_keyword?seoData.seo_keyword:'')
        setSeoDescription(seoData.seo_description ? seoData.seo_description:'Carryon Bikes')
        setSeoTitle(seoData.title?seoData.title:'Carryon.bike')
        return () => {
        }
    }, [seoData])
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{seoTitle}</title>
            <meta
                name="description"
                content={seoDescription}
            />
            <meta
                name="keywords"
                content={seoKeyword}
            />
        </Helmet>
    )
}

export default CustomMetaHandle
