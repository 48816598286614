import React, {useEffect, lazy, Suspense, useState} from 'react';
//import ExploreCarryon from './components/explore-carryon';
//import Events from './components/events';
import $ from 'jquery';
import CustomMetaHandle from '../../reuseables/CustomMetaHandle';
import {getSeoData} from '../../stores/actions/common-action'
//import InstagramFeed from '../bike-destination/components/instagram-feed';
const Header = lazy(() => import('../../includes/Header') );
const Banner = lazy(() => import('./components/banner') );
const LateLoadedComponent = lazy(() => import('./LateLoadedComponent') );

const Home =(props)=>{
	
	const [scrollY, setScrollY] = useState( 0 );
	const [seoData, setSeoData] = useState({});

	const _loadRestPart = () =>{
		setTimeout(()=>{
			setScrollY( true );
		}, 100);
	}
	
	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true);
		getSeoData({url:'/'}).then(res=>{
			if(!res.error){
				setSeoData(res.data)
			}
		});

		_loadRestPart();
		
		return () => {
			window.removeEventListener('scroll', handleScroll, true);
		}
	
	}, [])


	const handleScroll =(e)=>{
	
		let window_height = window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;
		let window_top_position = document.documentElement.scrollTop || document.body.scrollTop;
		let window_bottom_position = window_top_position + window_height
		var $animation_elements = $('.animation-element');
		
		$.each($animation_elements, function() {

			var $element = $(this);
		
			var element_height = $element.outerHeight();
		
			var element_top_position = $element.offset().top;
		
			var element_bottom_position = (element_top_position + element_height);
			 
		
			if ((element_bottom_position >= window_top_position) &&
		
				(element_top_position <= window_bottom_position)) {
		
			  $element.addClass('in-view');
		
			} else {
		
			  $element.removeClass('in-view');
		
			}
		
		  });
	}


    return (
        <>
		<CustomMetaHandle seoData={seoData}/>
		<Suspense fallback={<div/>}>
			<Header/>
		</Suspense>
		<Suspense fallback={<div/>}>
			<Banner is_home={true}/> 
		</Suspense>
		{ scrollY &&
			<Suspense fallback={<div/>}>
				<LateLoadedComponent/>
			</Suspense>
			
		}
        
        </>
    )
}

export default Home
